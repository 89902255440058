import React, { useState, useCallback } from 'react';
import Slider from 'rc-slider/lib/Slider';
import * as Styles from '../styles/components/pricing.module.scss';
import 'rc-slider/assets/index.css';
import RoundButton from './roundedButton';

const Pricing = () =>
{
    const [value, setValue] = useState(0);
    const [price, setPrice] = useState(2);
    const [clicks, setClicks] = useState(5000);
    const [msg, setMsg] = useState('');

    const onSliderChange = useCallback((step) =>
    {
        let tempClicks;
        if (step < 6) // <= 10,000 clicks
        {
            tempClicks = step * 1000 + 5000;
        }
        else if (step < 11) // <= 50,000 clicks
        {
            tempClicks = (step - 4) * 10000;
        }
        else // 50,000 < clicks <= 500,000
        {
            tempClicks = (step - 8) * 25000;
        }
        if (tempClicks <= 5000)
        {
            setPrice(2);
            setMsg('');
        }
        else if (tempClicks < 30000)
        {
            setPrice((2 + ((tempClicks - 5000) * 0.0005)).toFixed(2));
            setMsg('');
        }
        else if (tempClicks < 50000)
        {
            setPrice((14.5 + ((tempClicks - 30000) * 0.00045)).toFixed(2));
            setMsg('10% Off! The more clicks you get, the more you save!');
        }
        else if (tempClicks < 75000)
        {
            setPrice((23.25 + ((tempClicks - 50000) * 0.00045)).toFixed(2));
            setMsg('20% Off! The more clicks you get, the more you save!');
        }
        else if (tempClicks < 100000)
        {
            setPrice((33.5 + ((tempClicks - 75000) * 0.00035)).toFixed(2));
            setMsg('30% Off! The more clicks you get, the more you save!');
        }
        else if (tempClicks < 250000)
        {
            setPrice((43.25 + ((tempClicks - 100000) * 0.0003)).toFixed(2));
        }
        else
        {
            setPrice((87.25 + ((tempClicks - 250000) * 0.00025)).toFixed(2));
            setMsg('50% Off! The more clicks you get, the more you save!');
        }
        setValue(step);
        setClicks(tempClicks);
        console.log(value, price, clicks);
        // if(value )
    });

    // const price = useState(2.0);
    return (
        <div className={Styles.container}>
            <div className={Styles.info}>
                <div className={Styles.title}>
                    One For All
                </div>
                <hr></hr>
                <ul>
                    <li className="border-b py-4">30 Day Free Trial</li>
                    <li className="border-b py-4">Basic, General &amp; Amazon Links</li>
                    <li className="border-b py-4">Analytics Dashboard</li>
                    <li className="border-b py-4">Scalable Pricing Based on Usage</li>
                    <li className="border-b py-4">5,000 Clicks per month free <br></br> Then £0.50 per 1,000 clicks</li>
                </ul>
                <div>
                    <div className={Styles.from}>
                        <span>From </span>
                        £2
                        <span>per month</span>
                    </div>
                    <RoundButton classNames={Styles.button} style="gradient" href="https://builder.locally.link/register" text="Start Free Trial" />
                </div>

            </div>
            <div className={Styles.price}>
                <div className={Styles.title}>
                    Only Pay For What You Use
                </div>
                <hr></hr>
                <div className={Styles.sliderContainer}>
                    <h2>{clicks} clicks - £ {price}</h2>
                    <Slider
                        min={0}
                        max={28}
                        defaultValue={0}
                        step={1}
                        trackStyle={{ height: 10 }}
                        ariaLabelForHandle="Pricing Slider"
                        handleStyle={{
                            height: 28,
                            width: 28,
                            // marginLeft: -14,
                            marginTop: -9,

                        }}
                        railStyle={{ height: 10 }}
                        value={value}
                        onChange={onSliderChange}
                    />
                    <h3>{msg}</h3>
                </div>
                <div className={Styles.bordered}></div>
                <h3>Need over 500,000 clicks per month?</h3>
                <h3>Enterprise options available - scalable solutions for your business.</h3>
                <RoundButton classNames={Styles.button} style="gradient" href="mailto:hello@locally.link?subject=Business%20Account%20Enquiry" text="Contact Us" />
            </div>
        </div>
    );
};
export default Pricing;

import React from 'react';
import { heading, jump } from '../styles/components/heading.module.scss';

const Heading = ({ size: Size, text, id }) => (
    <div {...id && { id }} className={`${heading} ${id && jump}`}>
        <Size >{ text }</Size>
    </div>

);

export default Heading;
Heading.defaultProps = {
    size: 'h1',
};
